document.addEventListener("DOMContentLoaded", function () {
  //Main Nav toggle
  const nav = document.querySelector("#mobile-nav");
  const header = document.querySelector(".main-header");
  const opener = document.querySelector(".mobile-nav-opener");

  opener.addEventListener("click", function (event) {
    
    event.preventDefault();

    header.classList.toggle("nav-open");
    nav.classList.toggle("active");

  });

});


document.addEventListener("DOMContentLoaded", function () {
  //Main Nav toggle
  const search = document.querySelector("#main-search-wrapper");
  const opener = document.querySelector(".main-search-open");
  const mobopener = document.querySelector(".mobile-search-open"); 
  const closer = document.querySelector(".main-search-close");

  opener.addEventListener("click", function (event) {
    
    event.preventDefault();
    search.classList.toggle("active");

    document.getElementById("search-field").focus();

  });

  mobopener.addEventListener("click", function (event) {
    
    event.preventDefault();
    search.classList.toggle("active");

    document.getElementById("search-field").focus();

  });

  closer.addEventListener("click", function (event) {
    
    event.preventDefault();
    search.classList.toggle("active");

  });

});




/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.about-navigation .submenu li.active').closest('.has-submenu').removeClass('active');
        //$('.primary-navigation .submenu li.active').closest('.has-submenu').removeClass('active');

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        
        $('.featured-products').slick({
          infinite: true,
          slidesToShow: 3,
          cssEase: 'linear',
          autoplay: false,
          arrows: true,
          swipeToSlide: true,
          touchThreshold: 50,
          dots: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]

        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'how_to_buy': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        
        $('#gform_2 input[type="submit"]').click(function(event) {
            
          event.preventDefault();

          var product_name = $("#input_2_1 option:selected").text();
          var region_name = $("#input_2_3 option:selected").text();

          var product = $('#input_2_1').val();
          var region = $('#input_2_3').val();
          var state = $('#input_2_4').val();



          console.log(state);
          
          if (state) {

            state = state.toLowerCase().replace(/ /g, '-');

            console.log(state);

            var results = '.' + product + '.' + region + '.' + state;

          } else {

            var results = '.' + product + '.' + region;

          }

          console.log(results);

          $('#input_3_11').val(product_name);
          $('#results-region').html(region_name);
          $('#results-product-type').html(product_name);
          
          $('.how-to-buy-cell').hide();

          if ( $(results).length ) {
            
            $(results).show();      

          } else {
            
            $('.no-results').show();  

          }
          
          $('.how-to-buy-results').show();
          
          $('.how-to-buy-form').show();      

          $('html,body').animate({
             scrollTop: $('.how-to-buy-results').offset().top
          });

        });

      }
    },
    'single_product': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        
        $('.gallery-nav--item').click(function(e){

          $('.gallery-nav--item').removeClass('active');

          $(this).addClass('active');

          e.preventDefault();
          var img = $(this).data('view');
          $('.gallery-view img').attr('src', img);

        });

      }
    },
    'resources': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        //Resource filter dropdown / children categories
        $("li.resource-link.has-children i").on("click", function () {
          $(this).parent("li").toggleClass("is-open");
          $(this).parent("li").children("ul").slideToggle();
        });

        //Open sub cat menu if child is selected onload
        $(document).ready(function () {
          const activeLi = $("li.resource-link.has-children li.active");
          if (activeLi) {
            activeLi.parent("ul.children").css("display", "block");
            activeLi
              .parent("ul.children")
              .parent("li.resource-link.has-children")
              .addClass("is-open");
          }
        });   
      }
    },

    'post_type_archive_product': {
      init: function() {
        
        $('.page-header-slider').slick({
          infinite: true,
          cssEase: 'linear',
          autoplay: true,
          arrows: false,
          dots: false,
          fade: true,
          speed: 1000
        });

      }
    },
    'tax_product_category': {
      init: function() {
        
        $( '.tabs-panel' ).each(function( index ) {
          
          var tab = $(this).attr('id');
          
          console.log(tab);

          var tabLength = $('#' + tab + ' .categories').children('.category').length;

          $('.tab-count-' + tab).html('(' + tabLength + ')');
        
        });

      }

    },
    'careers': {
      init: function() {    
        var element = $('#gnewtonCareerBody').detach();
        $('#job-openings .flexible-cell').append(element);
      }

    },
    'postid_513': {
      init: function() {    

        var hotspots = $('.hotspot');
        hotspots.each(function() {
          $(this).on('click', function(){
            if( $('.is-focused')[0] !== $(this)[0] ){
              $('.is-focused').removeClass('is-focused');
            }
            
            $(this).toggleClass('is-focused');
          });

        });

      }

    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.










/*

$('.gallery').each(function( index ) {
  var gallery = $(this).attr('id');
  var lightbox = $('#' + gallery + ' a').simpleLightbox({});
});

//Simple Lightbox - Already Installed
var lightbox = $('.gallery-gallery a').simpleLightbox();

// Slick Slideshow - Already Installed
$('.home-slideshow').slick({
  infinite: true,
  cssEase: 'linear',
  autoplay: true,
  arrows: true,
  dots: true
});

// Smooth Scroll
var topOffset = 0;
$(".scroll-to, .scroll-to-li a").not('[href="#"]').not('[href="#0"]').click(function(t){if(location.pathname.replace(/^\//,"")===this.pathname.replace(/^\//,"")&&location.hostname===this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(t.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-topOffset},1e3,function(){var t=$(e);if(t.focus(),t.is(":focus"))return!1;t.attr("tabindex","-1"),t.focus()}))}});

*/
